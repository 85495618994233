import React, {useCallback, useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import BackButton from '../Components/BackButton';
import styled from 'styled-components';
import {Link, navigate} from 'gatsby';
import * as Ant from 'antd';
import {MEMBER_TYPE} from '../dictionary';
import Hint from '../Widgets/Hint';
import Row from '../Widgets/Row';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Anchor from '../Widgets/Anchor';
import Tabs from '../Widgets/Tabs';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import UserGroupDialog from '../Dialogs/UserGroupDialog';

const appConfig = require('../data.json');
const qs = require('query-string');

const PAGINATION = {
  current: 1,
  pageSize: 20,
};

function MemberBlock({group, id}) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    group: id,
    ...PAGINATION,
  });
  const [records, setRecords] = useState([]);

  const changeFilters = useCallback(
    (obj) => setFilters((prev) => ({...prev, ...obj})),
    [],
  );

  const getRecords = useCallback(async () => {
    setLoading(true);
    try {
      let resp = await actions.getMembers({
        search: filters.search,
        offset: (filters.current - 1) * filters.pageSize,
        limit: filters.pageSize,
        group: filters.group,
      });
      setRecords(resp.results);
      setTotal(resp.count);
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  }, [actions, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const addToGroup = useCallback(
    async (user, group_id) => {
      setLoading(true);
      try {
        await actions.editMember({
          id: user.id,
          group: group_id,
        });
        Ant.message.success('成功加入群組');
        getRecords();
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    },
    [actions, getRecords],
  );

  return (
    <Block>
      <div className="row" style={{margin: 0}}>
        <h2>會員列表</h2>
        <div style={{flex: 1}} />
        <div style={{marginRight: 5}}>搜尋會員</div>
        <Ant.Input.Search
          onSearch={(text) =>
            changeFilters({
              ...PAGINATION,
              search: text,
            })
          }
          allowClear
          placeholder="搜尋會員(姓名/電話/抬頭/統編/信箱)"
          style={{marginRight: 10}}
        />
        <Button
          type="primary"
          size="small"
          onClick={() => getRecords()}
          icon={<Refresh color="#fff" size={18} style={{marginRight: 5}} />}>
          刷新
        </Button>
      </div>
      <div className="divider" />
      <div>
        <Tabs
          value={filters.group}
          onChange={(value) => changeFilters({...PAGINATION, group: value})}
          options={[
            {label: '群組會員', value: group.id},
            {label: '全部會員', value: undefined},
          ]}
          style={{marginBottom: 20}}
        />

        <Ant.Table
          loading={loading}
          dataSource={records}
          rowKey="id"
          columns={[
            {
              title: '編號',
              dataIndex: 'id',
              key: 'id',
              render: (value) => (
                <Anchor to={`/member/?id=${value}`}>{value}</Anchor>
              ),
            },
            {title: '名稱', dataIndex: 'name', key: 'name'},
            {title: '群組', dataIndex: 'group_name', key: 'group_name'},
            {
              title: '會員身份',
              dataIndex: 'user_type',
              key: 'user_type',
              render: (value) => MEMBER_TYPE[value],
            },
            {title: '公司抬頭', dataIndex: 'ent_name', key: 'ent_name'},
            {title: '公司統編', dataIndex: 'gui_number', key: 'gui_number'},
            {title: '信箱', dataIndex: 'email', key: 'email'},
            {
              title: '',
              key: 'action',
              render: (record) => (
                <Row>
                  <Button
                    type="primary"
                    color={
                      !record.group
                        ? appConfig.colors.main
                        : record.group === group.id
                        ? appConfig.colors.error
                        : '#999'
                    }
                    // disabled={record.group && record.group !== group.id}
                    onClick={() => {
                      addToGroup(
                        record,
                        !record.group
                          ? group.id
                          : record.group === group.id
                          ? null
                          : group.id,
                      );
                    }}>
                    {!record.group
                      ? '加入群組'
                      : record.group === group.id
                      ? '移除會員'
                      : '轉移至此群組'}
                  </Button>
                  {record.group && record.group !== group.id ? (
                    <Hint type="info">已有其他群組</Hint>
                  ) : null}
                </Row>
              ),
            },
          ]}
          pagination={{
            pageSize: filters.pageSize,
            current: filters.current,
            total,
          }}
          onChange={(pagination) =>
            changeFilters({
              pageSize: pagination.pageSize,
              current: pagination.current,
            })
          }
        />
      </div>
    </Block>
  );
}

export default function UserGroupDetailPage(props) {
  const {location} = props;
  const params = qs.parse(location.search);
  const id = parseInt(params.id);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  const [record, setRecord] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const getRecord = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        let resp = await actions.getUserGroup(id);
        setRecord(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button type="primary" onClick={() => setOpenDialog(true)}>
          編輯
        </Button>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={getRecord}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>
      {record && (
        <Block style={{marginBottom: 20}}>
          <h2>
            #{id} {record.name}
          </h2>
          <div className="divider" />
          <div>
            <div className="row">
              <h4>群組編號</h4>
              <div>#{record.id}</div>
            </div>
            <div className="row">
              <h4>群組名稱</h4>
              <div>{record.name}</div>
            </div>
            <div className="row">
              <h4>群組備註</h4>
              <div>{record.note}</div>
            </div>
          </div>
        </Block>
      )}

      {record && <MemberBlock group={record} id={id} />}

      <UserGroupDialog
        visible={openDialog}
        onClose={() => setOpenDialog(false)}
        onUpdate={getRecord}
        record={record}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
